@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .page-navbar {
        @apply fixed w-full flex flex-wrap items-center justify-between py-2 navbar-expand-lg navbar-light;
    }

    .nav-menu {
        @apply p-0;
    }

    .mobile-view-btn {
        @apply border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline;
    }

    .flex-container {
        @apply flex items-center;
    }

    .vertical-navs {
        @apply nav-link my-0 pl-3 flex items-center;
    }

    /* Modal Calsses */
    .modal {
        @apply fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto;
    }
    .modal-dialog {
        @apply modal-dialog-centered relative pointer-events-none;
    }
    .modal-content {
        @apply border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current;
    }
    .modal-header {
        @apply flex flex-shrink-0 items-center justify-between pb-0 p-4;
    }
    #subscribeModal .modal-header {
        @apply pb-4;
    }
    .modal-footer {
        @apply flex flex-shrink-0 flex-wrap items-center justify-end p-4;
    }
    .btn-close {
        @apply  box-content w-4 h-4 p-1 text-black border-none rounded-none focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline;
    }

    /* Carousal buttons */
    .carousel-control-prev {
        @apply  absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0;
    }
    .carousel-control-next {
        @apply  absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0;
    }

    .carousel-control-next-icon,.carousel-control-prev-icon {
        @apply inline-block bg-no-repeat;
    }

    .carousel-item {
        @apply relative float-left w-full;
    }

    /* Gap classes */
    .box-gap {
        @apply xl:gap-5 lg:gap-4 md:gap-2 gap-5 md:p-0 lg:p-0 p-5;
    }

    .cart-notification {
        @apply absolute h-fit justify-center text-xs text-white px-1.5 py-0.5 shadow rounded-full;
    }
    .input-style {
        @apply w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-300 bg-white dark:bg-[#283046];
    }
}

@layer utilities {

    html,
    #root,
    #page-wrapper {
        --theme-primaryColor: #74ab50;
        --theme-darkColor: #3e4826;
        --theme-secondayColor: #0f0101;
    }

    .primary-clr {
        color: var(--theme-primaryColor);
    }

    .secondary-clr {
        color: var(--theme-secondayColor);
    }

    .dark-clr {
        color: var(--theme-darkColor);
    }

    .bg-primary-clr {
        background-color: var(--theme-primaryColor);
    }

    .bg-secondary-clr {
        background-color: var(--theme-secondayColor);
    }

    .bg-dark-clr {
        background-color: var(--theme-darkColor);
    }

    .text-white {
        color: #fff;
    }
    
    .bg-white {
        background-color: #fff;
    }

    .side-img {
        position: absolute;
        object-fit: cover;
        object-position: 40% 50%;
        right: 0px;
        top: 0px;
        width: 328px;
        height: 100%;
    }

    #subscribeModal {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(0);
        transition: all .25s linear;
        position: fixed;
        z-index: 99999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      #subscribeModal.show {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }

    .modal-box {
        box-shadow: 0 10px 24px 0 rgb(54 61 77 / 15%);
    }

    #subscribeModal .modal-dialog .btn-close {
        background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%230f0f0f%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
        opacity: 0.5;
    }

    #subscribeModal .modal-dialog .btn-close:hover {
        background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%230f0f0f%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
        opacity: 1;
    }
}